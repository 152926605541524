import React from "react";
import "./aboutUs.css";
import Header from "../../Component/Header/Header";
import Footrer from "../../Component/Footer/Footrer";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  const currentUrl = window.location.href;
  return (
    <>
      <div className="aboutus_header header">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>TMS | About Us</title>
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
        <div className="container text_p">
          <p>
            Home <span style={{ marginLeft: "15px" }}>About Us</span>
          </p>
        </div>
      </div>
      <div className="wrapper wrapper_AoutUS">
        <div className="container">
          <div className="about_us_content">
            <div className="content_main">
              <h2>About Us</h2>
              <p>
                Welcome to <span style={{color:"red"}}>The Migration School</span>, your trusted partner in
                navigating the complex world of visa applications. We specialize
                in offering practical, hands-on training for individuals seeking
                Tourist, Student, and Work visas. Our mission is to empower you
                with the knowledge and skills needed to confidently manage your
                visa application process, ensuring a smooth journey to your
                desired destination.
              </p>
            </div>
            <div className="content_main">
              <h4>Our Expertise</h4>
              <p>
                At The Migration School, we understand that each visa category
                comes with its own unique set of requirements and challenges.
                Our expert-led courses are designed to demystify the application
                process, providing you with clear, actionable insights. Whether
                you're planning a vacation abroad, seeking education
                opportunities in a foreign country, or aiming to pursue a career
                overseas, our courses equip you with the tools you need to
                succeed.
              </p>
            </div>
            <div className="content_main">
              <h4>Why Choose Us?</h4>
              <ul>
                <li>
                  <strong>Comprehensive Curriculum:</strong>Our courses cover
                  every aspect of the visa application process, from document
                  preparation and interview techniques to understanding legal
                  requirements and navigating potential challenges.
                </li>
                <li>
                  <strong>Expert Instructors: </strong>Our team of experienced
                  visa consultants and legal professionals bring real-world
                  knowledge to the classroom, ensuring you receive up-to-date
                  and practical advice.
                </li>
                <li>
                  <strong>Hands-On Learning: </strong> We believe in learning by
                  doing. Our courses include interactive workshops, mock
                  interviews, and case studies, giving you the confidence to
                  handle your visa application with ease.
                </li>
                <li>
                  <strong>Supportive Community: </strong> Join a network of
                  like-minded individuals who share your goals and aspirations.
                  Our community is here to support you every step of the way.
                </li>
              </ul>
            </div>
            <div className="content_main">
              <h4>Our Courses</h4>
              <ul>
                <li>
                  <strong>Languages Course: </strong>  Learn French, German, English, Spanish, Hindi from A1 level to C2 level.
                </li>
                <li>
                  <strong>Tourist Visa Course: </strong>Learn how to prepare a
                  compelling application, understand visa conditions, and get
                  tips on passing your visa interview.
                </li>
                <li>
                  <strong>Student Visa Course: </strong> Get detailed guidance
                  on securing a student visa, from choosing the right school to
                  fulfilling visa requirements and adjusting to life abroad.
                </li>
                <li>
                  <strong>Work Visa Course: </strong> Gain insights into the
                  work visa process, including employer sponsorship, visa
                  categories, and long-term career planning in a foreign
                  country.
                </li>
              </ul>
            </div>
            <div className="content_main">
              <h4>Our Vision</h4>
              <p>
                At The Migration School, we believe that every individual
                deserves the opportunity to explore the world and achieve their
                dreams. We are committed to making the visa application process
                as accessible and straightforward as possible, helping you turn
                your travel, study, and work ambitions into reality.
              </p>
            </div>
            <div className="content_main">
              <h4>Get in Touch</h4>
              <p>
                Ready to take the next step in your journey? Contact us today to
                learn more about our courses and how we can assist you in
                achieving your visa goals.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <Footrer />
      </div>
    </>
  );
};

export default AboutUs;
