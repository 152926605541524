import React, { useState } from "react";
import Header from "../../Component/Header/Header";
import "./courses.css";
import { loadStripe } from "@stripe/stripe-js";
import Footrer from "../../Component/Footer/Footrer";
import { Helmet } from "react-helmet";
import { CardData } from "./CardsData";

const Courses = () => {
  const [amount, setAmount] = useState("");

  // payment integration
  const makePayment = async (amount) => {
    const stripe = await loadStripe(
      "pk_live_51PvCsHKepGIuKOp2CzNfaN3TUP1qBfKWnWxMTbkSBD4y2O3LxdGa02j2Wg7r9gNvaf2q9ZDj6h7mdQktjlkAHKEx00MgknRt8q"
    );

    const body = {
      amount: parseInt(amount), // Amount in cents (e.g., 1000 cents = 10 AUD)
      currency: "aud", // Currency code
    };
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await fetch(
      "https://backend-visa2.vercel.app/api/tmsPayment/tmsCheckout",
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
      }
    );

    const session = await response.json();

    const result = stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      console.log(result.error);
    }
  };


  const currentUrl = window.location.href;
  return (
    <>
      <div className="header Courses_header">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>TMS | Courses</title>
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
        <div className="container text_p">
          <p>
            Home{" "}
            <span style={{ marginLeft: "15px" }}>VISA Management Course</span>
          </p>
        </div>
      </div>
      <div className="wrapper">
        <div className="container">
          <div className="homepage-main">
            <div className="home_content">
              <div className="main_course_content">
                <h2 style={{ color: "red" }}>
                  VISA & Immigration Business Management
                </h2>
                <h4 style={{ fontSize: "13px", marginBottom: "25px" }}>
                  Extensive practical training for starting Visa Business
                  without depending on agents, start your own Visa & Travel
                  Agency Easily
                </h4>
                <h5
                  style={{
                    color: "#3838ed",
                    fontSize: "18px",
                    marginTop: "25px",
                    marginBottom: "8px",
                  }}
                >
                  Return of Investment easily possible within 2 months
                </h5>
                <h5>The Practical Course!</h5>
                <h6>
                  India’s Leading Practical Institute For Practical Visa Course!
                </h6>
                <h6>Benefits of VISA & Immigration Class:</h6>
                <ol>
                  <li>
                    Learn Online and Get Quick Edge in Your Professional Career!
                  </li>
                  <li>Set Up Your Own Dream Business!</li>
                  <li>Live & Practical Class by The Travel Experts</li>
                  <li>Get Complete Flexibility of Class Timings</li>
                </ol>
                <h5>About the course:</h5>
                <p>
                  The course provides a comprehensive knowledge of VISA
                  Management & Immigration Rules. A student can get a <br />{" "}
                  complete knowledge of VISA Filing Process. Learn practical to
                  understand the VISA Management for various countries <br /> in
                  Travel-Tourism Industry. Under this VISA management programme
                  you get 360° knowledge of VISA & Immigration <br /> process
                  and learn in-depth knowledge of VISA business industry.
                </p>
                <ul>
                  <li>
                    Duration:2/3 Months & Customizable as per requirements
                  </li>
                  <li>
                    Class Schedule: 2 Days in a week (according to course)
                  </li>
                  <li>Class Duration: 1-2 hours (Based on course)</li>
                  <li>All classes will be in Evening</li>
                  <li>All courses are available online</li>
                  <li>
                    The student has to deposit the 50% at the time of admission
                  </li>
                  <li>All courses are practical</li>
                </ul>
              </div>

              <div className="mainPge_withCards">
                <div className="courses_sections">
                  <div className="courses_visa">
                    <h5>
                      Course Name:{" "}
                      <span style={{ color: "blue" }}>Languages Course</span>{" "}
                    </h5>
                    <div>
                      <strong>
                        <span style={{ color: "green" }}>Languages :</span>{" "}
                        English, French, German, Spainish, Hindi
                      </strong>
                    </div>
                    <strong>
                      <span style={{ color: "green" }}>Services Country :</span>{" "}
                      India, Australia, USA, Canada
                    </strong>
                    <p style={{ marginBottom: "12px" }}>
                      <strong>
                        <span style={{ color: "green" }}>Duration:</span>
                      </strong>{" "}
                      3/4 Months
                    </p>
                  </div>
                  <div className="Courses_cards">
                    <div className="card">
                      <div className="card-content">
                        <h2 className="course-name"> Languages Course</h2>

                        <h3 className="price">
                          Fees: <span></span>
                        </h3>
                        <div className="input_for_price">
                          <input
                            type="text"
                            onChange={(e) => setAmount(e.target.value)}
                          />
                        </div>
                        <button
                          className="button"
                          type="submit"
                          onClick={() => makePayment(amount)}
                        >
                          {/* {loading ? "Processing..." : "Pay"} */}
                          PAY
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mainPge_withCards">
                {CardData.map((course, index) => (
                  <div key={index} className="courses_sections">
                    <div className="courses_visa">
                      <h5>
                        Course Name:{" "}
                        <span style={{ color: "blue" }}>
                          {course.CourseName}
                        </span>{" "}
                      </h5>
                      {course.Languages && (
                        <strong>
                          <span style={{ color: "green" }}>Languages':</span>{" "}
                          {course.Languages}
                        </strong>
                      )}
                      <p style={{ marginBottom: "12px" }}>
                        <strong>
                          <span style={{ color: "green" }}> Class Time:</span>
                        </strong>{" "}
                        {course.ClassTime}
                      </p>
                      <p style={{ marginBottom: "12px" }}>
                        <strong>
                          <span style={{ color: "green" }}>Duration:</span>
                        </strong>{" "}
                        {course.Duration}
                      </p>
                      <p style={{ marginBottom: "12px" }}>
                        <strong>
                          <span style={{ color: "green" }}>Fees:</span> ${" "}
                          {course.AUD}{" "}
                        </strong>
                      </p>
                      <p style={{ marginBottom: "12px" }}>
                        {course.CountriesIncluded && (
                          <strong>
                            <span style={{ color: "green" }}>
                              Countries Included:
                            </span>{" "}
                            {course.CountriesIncluded}
                          </strong>
                        )}
                      </p>
                      <p>
                        <strong>Course Content:</strong>
                        {course.CourseContent}
                      </p>
                      <h4 style={{ color: "blue", marginTop: "8px" }}>
                        {" "}
                        {course.ReturnP}{" "}
                      </h4>
                    </div>
                    <div className="Courses_cards">
                      <div className="card">
                        <div className="card-content">
                          <h2>{course.CourseName}</h2>
                          <h3 className="price">
                            Fees: <span>$ {course.AUD}</span>
                          </h3>
                          <button
                            className="button"
                            type="submit"
                            onClick={() => makePayment(course.AUD)}
                          >
                            {/* {loading ? "Processing..." : "Pay"} */}
                            Enroll Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="Vedio_section">
            <h3 className="Vedio_sections">Our Video Section</h3>
            <div className="video_container">
              <div className="edtech_vedio">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/GKuBxLIYHX0?si=vxnXGp4KdJuQhZnK"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="edtech_vedio">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/dipSfzyAC20?si=SE6scnzpdPlEiPnl"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div className="video_container">
              <div className="edtech_vedio">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/tb-Vczht058?si=KPGgLfqXlDGE-_7d"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="edtech_vedio">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/osdXKjJDhUE?si=a2Q07yZibUt2IRYe"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <Footrer />
      </div>
    </>
  );
};

export default Courses;
