import React from "react";
import "./nav.css";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineRecordVoiceOver } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { PiGraduationCapBold } from "react-icons/pi";
import { TbArmchair2 } from "react-icons/tb";

const Header = () => {
  return (
    <>
      <div className="wrapper header_wraper1">
        <div className="container">
          <div className="nav">
            <div className="logo">
              <img src="/Images/TMS-Logo.png" alt="Logo" />
              <p className="company_name">
                {" "}             
                <span style={{ color: "blue" }}>The Migration School</span>{" "}
              </p>
            </div>
            <div className="nav_links">
        <ul>
          <NavLink exact to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
            <li>HOME</li>
          </NavLink>
          <NavLink to="/About-Us" className={({ isActive }) => (isActive ? 'active' : '')}>
            <li>ABOUT US</li>
          </NavLink>
          <NavLink to="/Courses" className={({ isActive }) => (isActive ? 'active' : '')}>
            <li>COURSES</li>
          </NavLink>
          <NavLink to="/Contact-Us" className={({ isActive }) => (isActive ? 'active' : '')}>
            <li>CONTACT US</li>
          </NavLink>
        </ul>
      </div>
          </div>
        </div>
        <div className="header_wraper">
          <div className="header_main">
            <div className="header_img">
              <h2 style={{ color: "red", fontSize: "22px" }}>
                The BEST TRAINING INSTITUTE FOR
              </h2>
              <h1>TRAVEL & TOURISM</h1>
            </div>
          </div>
          <div className="course_iccons_main_wrap">
            <div className="cousr_iconsAnd_link">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-md-4">
                    <div className="icons_content icons_content1">
                      <div className="header_iconsYllow">
                        <MdOutlineRecordVoiceOver />
                      </div>
                      <div className="Content_iconssss">
                        <h6>Best Online Course</h6>
                        <p>
                          <Link to={'/Courses'}>
                            Veiw More <MdKeyboardArrowRight />
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="icons_content icons_content2">
                      <div className="header_iconsYllow">
                        <PiGraduationCapBold />
                      </div>
                      <div className="Content_iconssss">
                        <h6>100% Placement Support</h6>
                        <p>
                          <Link to={'/Courses'} >
                            Veiw More <MdKeyboardArrowRight />
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="icons_content">
                      <div className="header_iconsYllow">
                        <TbArmchair2 />
                      </div>
                      <div className="Content_iconssss">
                        <h6>Job Oriented Course</h6>                      
                        <p>
                          <Link to={'/Courses'}>
                            Veiw More <MdKeyboardArrowRight />
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
