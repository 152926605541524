import React from "react";
import "./footer.css";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoLogoFacebook } from "react-icons/io5";
import { AiFillTwitterCircle } from "react-icons/ai";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { GrYoutube } from "react-icons/gr";
import { Link } from "react-router-dom";

const Footrer = () => {
  return (
    <>
      <div className="wrapper_main">
        <div className="footer_main">
          <div className="container">
            <div className="footer_Logo">
              <img src="/Images/TMS-Logo.png" alt="footer-logo" />
              <p className="company_name">
                {" "}
                <span style={{ color: "red" }}>The</span>{" "}
                <span style={{ color: "green" }}>Migration</span>{" "}
                <span style={{ color: "blue" }}>School</span>{" "}
              </p>
            </div>
            <div className="footer_content">
              <div className="contact_us">
                <h4>CONTACT US</h4>
                <ul>
                  <li>
                    <span>
                      <FaPhoneAlt />
                    </span>{" "}
                    0413180732
                  </li>
                  <li>
                    <span>
                      <MdEmail />
                    </span>{" "}
                    contact@themigrationschool.com
                  </li>
                </ul>
              </div>

              <div className="contact_us">
                <h4>PAGES</h4>
                <ul>
                  <li>
                    <Link to={'/Privacy-policy'}>Privacy Policy</Link>               
                  </li>
                  <li>                  
                  <Link to={'/Term-and-conditions'}>Terms And Conditions</Link>
                  </li>
                  <li>
                  <Link to={'/Refund-policy'}>Refund Policy</Link>
                  </li>
                  <li>
                  <Link to={'/shiping-policy'}>Shiping Policy</Link>
                  </li>
                </ul>
              </div>

              <div className="contact_us">
                <h4>FOLLOW US</h4>
                <p>
                  <span>
                    <Link
                      to="https://www.facebook.com/profile.php?id=61555803538931"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IoLogoFacebook />
                    </Link>
                  </span>
                  <span>
                    <Link>
                      <AiFillTwitterCircle />
                    </Link>
                  </span>
                  <span>
                    <Link
                      to="https://www.instagram.com/the_migration_school_"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <BiLogoInstagramAlt />
                    </Link>
                  </span>
                  <span>
                    <Link >
                      <GrYoutube />
                    </Link>
                  </span>
                </p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footrer;
