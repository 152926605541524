import React, { useEffect } from "react";
import Footrer from "../../Component/Footer/Footrer";
import { Helmet } from "react-helmet";
import Header from "../../Component/Header/Header";
import "./PolicyPages.css";

const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const currentUrl = window.location.href;
  return (
    <>
      <div className="main_wrapper_termand_condition">
        <div className="aboutus_header header">
          <Header />
          <Helmet>
            <meta charSet="utf-8" />
            <title>TMS | Refund Policy</title>
            <meta property="og:url" content={currentUrl} />
            <meta property="og:type" content="website" />
          </Helmet>
          <div className="container text_p">
            <p>
              Home <span style={{ marginLeft: "15px" }}>Refund Policy</span>
            </p>
          </div>
        </div>
        <div className="wrapper wrapper_AoutUS">
          <div className="container">
            <div className="about_us_content">
              <div className="content_main">
                <h2>Refund and Cancellation Policy</h2>
                <p>
                  At <span style={{ color: "red" }}>The Migration School</span>{" "}
                  , we strive to provide the best educational experience through
                  our vocational and visa education courses for multiple
                  countries. This Refund and Cancellation Policy outlines the
                  terms under which you may request a refund for our courses. By
                  enrolling in our courses, you agree to the terms stated below.
                </p>
              </div>
              <div className="content_main">
                <h4>Eligibility for Refunds</h4>
                <p>Refunds are available under the following conditions:</p>

                <ul>
                  <li>
                    <strong>Vocational and Visa Educational Courses:</strong>You
                    may request a refund only after attending the first class of
                    your course.
                  </li>
                  <li>
                    <strong>First-Class Refund: </strong>A refund request must
                    be made within 24 hours after attending the first class.
                    Refund requests made after this period will not be
                    entertained.
                  </li>
                  <li>
                    <strong>No Refund After First Class:</strong>Once you have
                    attended more than one class or accessed more than 10% of
                    the course content, you will not be eligible for a refund
                    under any circumstances.
                  </li>
                  <li>
                    <strong>
                      Courses for Australian Students (Self-Visa Filing):
                    </strong>
                    The same refund rules apply to courses designed for
                    Australian students seeking to file their own visa
                    applications.
                  </li>
                </ul>
              </div>
              <div className="content_main">
                <h4>How to Request a Refund</h4>
                <p>To request a refund, please follow these steps</p>
                <ul>
                  <li>
                    <strong>Submit a Written Request:</strong>Email your refund
                    request to <span style={{color:"red"}}>contact@themigrationschool.com</span>, including your
                    name, course details, the date of your first class, and the
                    reason for the refund request.
                  </li>
                  <li>
                    <strong>Refund Review: </strong>We will review your refund
                    request and determine whether it meets the eligibility
                    criteria outlined in this policy.
                  </li>
                  <li>
                    <strong>Processing Time:</strong> If your refund request is
                    approved, we will process your refund within 7–10 business
                    days. Refunds will be issued to the original payment method
                    used at the time of enrollment.
                  </li>
                </ul>
              </div>
              <div className="content_main">
                <h4> Non-Refundable Fees</h4>
                <p>Please note that the following are non-refundable:</p>
                <ul>
                  <li>Registration fees (if applicable)</li>
                  <li>
                    Any taxes or charges applied by third-party payment
                    processors
                  </li>
                  <li>
                    Any materials or resources provided as part of the course
                  </li>
                </ul>
              </div>
              <div className="content_main">
                <h4> Cancellation Policy</h4>
                <p>
                  If you wish to cancel your enrollment before attending the
                  first class, please contact us at
                  <span style={{color:"red"}}>contact@themigrationschool.com</span> as soon as possible. You may be
                  eligible for a full or partial refund depending on the timing
                  of the cancellation:
                </p>
                <ul>
                  <li>
                    <strong>Cancellation Before First Class:</strong>A full
                    refund (minus any applicable processing fees) will be issued
                    if the cancellation request is made prior to attending the
                    first class.
                  </li>
                  <li>
                    <strong>Cancellation After First Class:</strong>As per our
                    refund policy, no refunds or cancellations will be
                    entertained once you have attended more than one class.
                  </li>
                </ul>
              </div>
              <div className="content_main">
                <h4> Course Cancellation by The Migration School</h4>
                <p>
                  In the rare event that The Migration School cancels a course
                  due to unforeseen circumstances, we will offer the following
                  options:
                </p>
                <ul>
                  <li>
                    <strong>Full Refund:</strong>A full refund will be provided
                    if the course is canceled before it begins.
                  </li>
                  <li>
                    <strong>Alternative Course or Credit: </strong> If the
                    course is canceled after it has started, you may choose
                    between a pro-rated refund or credit toward another course.
                  </li>
                </ul>
              </div>
              <div className="content_main">
                <h4>Changes to this Refund and Cancellation Policy</h4>
                <p>
                  We reserve the right to modify or update this Refund and
                  Cancellation Policy at any time. Changes will be effective
                  immediately upon posting on this page. It is your
                  responsibility to review this policy periodically for any
                  updates.
                </p>
              </div>
              <div className="content_main">
                <h4> Contact Information</h4>
                <p>
                  If you have any questions about our Refund and Cancellation
                  Policy or need assistance with a refund request, please
                  contact us at:
                </p>
              </div>
            </div>
            <div className="tms_email_and_web">
              <h5 style={{ color: "red" }}>The Migration School</h5>
              <p>
                <strong>Website: </strong>https://themigrationschool.com
              </p>
              <p>
                <strong>Email: </strong>contact@themigrationschool.com
              </p>
            </div>
          </div>
        </div>
        <div className="footer">
          <Footrer />
        </div>
      </div>
    </>
  );
};

export default RefundPolicy;
