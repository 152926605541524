import React from "react";
import "./home.css";
import Header from "../../Component/Header/Header";
import Footrer from "../../Component/Footer/Footrer";
import { Helmet } from "react-helmet";

const Home = () => {
  const currentUrl = window.location.href;
  return (
    <>
      <div className="homeNav header">
        <Header />
        <Helmet>
          <meta charSet="utf-8" />
          <title>TMS | Home</title>
          <meta property="og:url" content={currentUrl} />
          <meta property="og:type" content="website" />
        </Helmet>
        <div className="container text_p">
          <p>
            Home 
          </p>
        </div>
      </div>
      <div className="wrapper wrapper_main_homePage">
        <div className="wrapper_mainHome">
          <div className="container">
            <div className="wrapper_homePage">
              <h5>Our Aim</h5>
              <h3>
                The Migration School is <br /> simple- to set you
                up for a successful career in
              </h3>
              <div className="paragrap_for_home">
                <ul>
                  <li>
                    <span>1</span> Visa Consulting Business
                  </li>
                  <li>
                    <span className="third_spain2">2</span> International Tour Booking Business
                    
                  </li>
                  <li>
                    <span className="third_spain">3</span>Student Counsellor related Jobs and Business 
        
                    
                  </li>
                </ul>
                <p className="para">
                  Whether your dream is to be a Travel Agent, work as a student counsellor, or as recruitment agent,
                  we can help you gain the skills you need to be success in this industry.
                  Click to know more about our courses
                
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper_counter">
          <div className="container">
            <div className="counter_content">
              <div className="counter">
                <p>
                  <img src="/Images/counter-img.png" alt="counter-icons" />
                </p>
                <h3>100%</h3>
                <p> Placement support</p>
              </div>
              <div className="counter">
                <p>
                  <img src="/Images/counter-img1.png" alt="counter-icons" />
                </p>
                <h3>100%</h3>
                <p> Job Oriented Courses</p>
              </div>
              <div className="counter">
                <p>
                  <img src="/Images/counter-img2.png" alt="counter-icons" />
                </p>
                <h3>100%</h3>
                <p>Experienced Faculty</p>
              </div>
              <div className="counter">
                <p>
                  <img src="/Images/counter-img3.png" alt="counter-icons" />
                </p>
                <h3>100%</h3>
                <p>Student Support</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <Footrer/>
      </div>
    </>
  );
};

export default Home;
